<template>
  <div>
    <el-menu collapse router class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
      <div class="flex items-center justify-center logo-box">标书演示附件</div>
      <el-menu-item index="/">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span slot="title">首页</span>
        </template>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span slot="title">可视化大屏</span>
        </template>
        <!-- <el-menu-item index="/home">设计器</el-menu-item> -->
        <el-menu-item index="/dataSource">财务数据</el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>产品设计</span>
        </template>
        <el-menu-item index="/bomDesigner">汽车设计</el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>富文本设计</span>
        </template>
        <el-menu-item index="/testTinyMce">富文本设计</el-menu-item>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>three可行性</span>
        </template>
        <el-menu-item index="/psychological">展示</el-menu-item>
        <el-menu-item index="/three">管道</el-menu-item>
        <el-menu-item index="/three2">管道2</el-menu-item>
        <el-menu-item index="/three3">心理demo2</el-menu-item>
        <el-menu-item index="/three4">心理demo3</el-menu-item>
        <el-menu-item index="/three5">心理demo4</el-menu-item>
        <el-menu-item index="/three6">心理demo5</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: 'f-menu',
  props:{
    isCollapse:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100vh;
}
.el-menu-vertical-demo{
  height: 100vh;
}
.logo-box{
  height: 60px;
}
</style>