import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/redirect',
    name: 'redirect',
    component: Layout
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      },
      {
        path: '/home',
        component: () => import('@/views/HomeView'),
        name: 'home',
      },
      {
        path: '/dataSource',
        component: () => import('@/views/dataSource/index'),
        name: 'dataSource',
      },
      {
        path: '/bomDesigner',
        component: () => import('@/views/bomDesigner/index'),
        name: 'bomDesigner',
      },
      {
        path: '/testTinyMce',
        component: () => import('@/views/testTinyMce/index'),
        name: 'testTinyMce',
      },
      {
        path: '/psychological',
        component: () => import('@/views/psychological/index'),
        name: 'psychological',
      },
      {
        path: '/three',
        component: () => import('@/views/three/index'),
        name: 'three',
      },
      {
        path: '/three2',
        component: () => import('@/views/three2/index'),
        name: 'three2',
      },
      {
        path: '/three3',
        component: () => import('@/views/three3/index'),
        name: 'three3',
      },
      {
        path: '/three4',
        component: () => import('@/views/three4/index'),
        name: 'three4',
      },
      {
        path: '/three5',
        component: () => import('@/views/three5/index'),
        name: 'three5',
      },
      {
        path: '/three6',
        component: () => import('@/views/three6/index'),
        name: 'three6',
      },
    ]
  },
  {
    path: '/largeScreen',
    component: () => import('@/views/largeScreen/index'),
    name: 'largeScreen',
  },
]

const router = new VueRouter({
  routes
})

export default router
