import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import f_json from '@/assets/json/demo';
import '@/assets/scss/common.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss' // global css
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import tinymce from 'tinymce';
Vue.prototype.$tinymce = tinymce;

// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入全局css
import './assets/scss/style.scss';

Vue.config.productionTip = false;
Vue.prototype.$f = f_json;

Vue.use(ElementUI);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
