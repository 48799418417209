const goodsList = [{
  id: '109380193810dw_eaj',
  name: '设计用汽车',
  type: '家用轿车',
  attribute: {
    sensitivity: 16,// 灵敏
    sensitiveness: 16,// 敏感
    durability: 16,// 耐久
    compatibility: 17,// 兼容
    Readability: 15,// 兼容
  },
  details:{
    discreteGraphics: 1,// 独显
    ifSSD: 4,// 固态硬盘
    motherboard: 1,// 主板
    monitor: 1,// 显示器
  }
},{
  id: '1093801312320dw_eaj',
  name: '设计用汽车',
  type: '家用轿车',
  attribute: {
    sensitivity: 16,// 灵敏
    sensitiveness: 16,// 敏感
    durability: 16,// 耐久
    compatibility: 17,// 兼容
    Readability: 15,// 兼容
  },
  details:{
    discreteGraphics: 1,// 独显
    ifSSD: 4,// 固态硬盘
    motherboard: 1,// 主板
    monitor: 1,// 显示器
  }
}]
const deviceList = [{
  name: '轮胎',
  type: '215/70R15',
  num: 4,
  price: 5000
}]
const deviceFormList = [{
  label: '发动机',
  value: 'discreteGraphicsList'
},{
  label: '轮胎',
  value: 'ifSSDList'
},{
  label: '方向盘',
  value: 'motherboardList'
},{
  label: '引擎',
  value: 'monitorList'
}]

const discreteGraphicsList = [{
  name: '昂科拉',
  type: '1.4T',
  num: '1',
  price: '28000',
  checked: false
},{
  name: '君威',
  type: '2.0T',
  num: '1',
  price: '58000',
  checked: false
},{
  name: '凯越',
  type: '2.4T',
  num: '1',
  price: '78000',
  checked: false
}]

const ifSSDList = [{
  name: '82－475',
  type: '5.5J',
  num: '4',
  price: '5000',
  checked: false
},{
  name: '83－487',
  type: '6.0J',
  num: '4',
  price: '8000',
  checked: false
},{
  name: '89－580',
  type: '7.5J',
  num: '4',
  price: '7000',
  checked: false
}]

const motherboardList = [{
  name: '82－475',
  type: '5.5J',
  num: '4',
  price: '5000',
  checked: false
},{
  name: '83－487',
  type: '6.0J',
  num: '4',
  price: '8000',
  checked: false
},{
  name: '89－580',
  type: '7.5J',
  num: '4',
  price: '7000',
  checked: false
}]

const monitorList = [{
  name: 'GW4G15',
  type: '2.4L',
  num: '1',
  price: '15000',
  checked: false
},{
  name: 'R20A7',
  type: '2.0L',
  num: '1',
  price: '18000',
  checked: false
},{
  name: 'R20A4',
  type: '2.4L',
  num: '1',
  price: '17000',
  checked: false
}]
export default{
  goodsList,
  deviceList,
  deviceFormList,
  discreteGraphicsList,
  ifSSDList,
  motherboardList,
  monitorList
}