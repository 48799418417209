<template>
  <div>
    <el-container>
      <!-- <div>
      <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
        <el-radio-button :label="false">展开</el-radio-button>
        <el-radio-button :label="true">收起</el-radio-button>
      </el-radio-group>
    </div> -->
      <el-aside>
        <fMenu :isCollapse="isCollapse"></fMenu>
      </el-aside>
      <el-container>
        <el-header class="header-box">
          <i class="zm-font-24" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="changeMenu"></i>
        </el-header>
        <el-main>
          <transition name="fade-transform" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import fMenu from './components/f-menu.vue'
export default {
  name: 'layout',
  data() {
    return {
      isCollapse: false
    }
  },
  components: {
    fMenu
  },
  methods: {
    changeMenu() {
      this.isCollapse = !this.isCollapse;
    }
  }
}
</script>
<style lang="scss" scoped>
.el-aside {
  width: auto !important;
  height: auto;
}
.header-box{
  line-height: 60px;
  box-shadow: 0 2px 15px rgba($color: #000000, $alpha: .1);
}
</style>